import React, { useState, useRef } from 'react';
import { Overlay, Popover, Container, Row, Col, Image, Button } from 'react-bootstrap';
import './DriverCalloutBox.scss';
import DefaultHeadShotImage from '../../../assets/images/headshot_placeholder.png';
import { RIDER_HEADSHOT_URL } from '../../../constants';

function DriverCalloutBox({ driver, race, driverCalloutState, isMobile }) {
    const [show, setShow] = useState(false);
    const [currentlyVisibleDriverCallout, setCurrentlyVisibleDriverCallout] = driverCalloutState;
    const [headshotUrl, setHeadShotUrl] = useState(RIDER_HEADSHOT_URL(driver.name.toLowerCase().replace(/\s+/g, '-')));
    const target = useRef(null);

    const handleClick = event => {
        setShow(!show);
        setCurrentlyVisibleDriverCallout(driver.id);
    };

    function onHide() {
        setCurrentlyVisibleDriverCallout(null);
        setShow(false);
    }

    function handleCloseButton() {
        onHide();
    }

    function handleHeadshotError() {
        setHeadShotUrl(DefaultHeadShotImage);
    }

    function placementAcordingToDevice() {
        return isMobile ? 'bottom' : 'right';
    }

    if (driver) {
        return (
            <>
                <span ref={target} className="callout-trigger" onClick={handleClick}>{driver.name}</span>
                <Overlay target={target.current} show={show} placement={placementAcordingToDevice()}>
                    <Popover className="driver-callout-box">
                        <Popover.Content>
                            <Container bsPrefix="callout-container" fluid>
                                <Button className="close-callout" onClick={handleCloseButton}/>
                                <Row className="no-gutters">
                                    <Col className="driver-headshot" xs={3} md={4}>
                                        <Image src={headshotUrl} onError={handleHeadshotError} alt="alt" fluid/>
                                    </Col>
                                    <Col className="driver-details" xs={9} md={8}>
                                        <p>From: {driver.hometown}</p>
                                        <p>No: {driver.number}</p>
                                        <p>Bike: {driver.vehicle.name}</p>
                                        <p>Team: {driver.team}</p>
                                        <p>Status: {driver.status}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </>
        );
    }
    return null;
}

export default DriverCalloutBox;